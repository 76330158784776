





























import { Component, Vue } from 'vue-property-decorator'
import { BButton } from 'bootstrap-vue'
import leftovers from '@/store/leftovers'
import { RouteNames } from '@/router'
import { Scalars } from '@/graphql/default/graphql.schema'
import { cart } from '@/store/cart/cartModule'
import terminalModule from '@/store/terminal'
const ProductCardMini = () => import('@/components/ProductCardMini.vue')

@Component({
  components: { ProductCardMini, BButton }
})

export default class catalogOfNewProducts extends Vue {
  readonly RouteNames = RouteNames;

  get newItems() {
    return leftovers.threeNewLeftovers
  }

  async addToCart(productId: Scalars['ID']): Promise<void> {
    cart.addItem({ productId, count: 1 })
  }

  get isShowAddToBasket() {
    return !terminalModule.getters.terminal?.isDemoMode
  }
}
